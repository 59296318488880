import { useEffect } from "react";

export default function News({articles}) {
  // const [title, url, content, publishedAt] = articles;
  useEffect(()=>{
    console.log(articles);
  },[]);

  return (
    <div className="section_article">
      {articles && articles.map((v,i)=>(
      <div className="article row m-3" key={i}>
        <div className="col-3">
          <img className="article_img" src={v.urlToImage} />
        </div>
        <div className="article_body col-9">
          <div className="article_title">
            <a href={v.url} target="_blank">
              {v.title}
            </a>
          </div>
          <div className="article_content">{v.description}</div>
        </div>
      </div>
      ))}
    </div>
  );
};
