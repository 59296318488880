import axios from "axios";
import { useEffect, useRef } from "react";
import { Container, Navbar } from "react-bootstrap";
import { useQuery } from "react-query";

export default function NavMenu() {

  const enabled = useRef(false);

  const client = axios.create({
    baseURL: "http://131.186.25.11:8080",
    timeout: 3000,
    headers: {
      "Cache-Control": "public,max-age=31536000",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    responseType: "json"
  });

  const getMenu = async () => {
    console.log('!getmenu');
    const { data } = await client.get('/menu');
    console.log(data);
    return data;
  };

  const options = {
    enabled : enabled.current,
    // onSuccess : () => {
    //   enabled.current = false;
    // },
    // onSettled : () => {
    //   console.log(enabled.current);
    // },
    staleTime: Infinity,
    cacheTime: 1000*60,
    keepPreviousData: true
  };

  const { data, isLoading, status, error, isPreviousData, refetch } = useQuery('menu', getMenu, options);

  useEffect(()=>{
    console.log(data);
    if (!data) {
      console.log(status);
      refetch();
    }
  },[]);

  let rendering = () => {
    let result = '';
    if (data && data.length > 0) {
      result = (
        <Navbar expand="sm" className="bg-body-tertiary mb-3">
          <Container>
            {/* <Navbar.Brand href="javascript:void(0);" onClick={getMenu} >Call</Navbar.Brand> */}
            {
              data.map((menu,i)=>(
                <Navbar.Brand href={menu.menu_name} key={i}>{menu.menu_txt}</Navbar.Brand>
              ))
            }
          </Container>
        </Navbar>
      );
    }
    return result;
  };


  if (isLoading) return <Navbar expand="sm" className="bg-body-tertiary mb-3">Loading...</Navbar>;
  if (error) return <Navbar expand="sm" className="bg-body-tertiary mb-3">ERROR</Navbar>;

  return (
    <>
    {
      rendering()
    }
    </>
  );
}
