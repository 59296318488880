import axios from "axios";
import { useEffect, useRef, useState } from "react";
import News from "./News";
import { useParams } from "react-router-dom";

export default function NewsList() {
  const { category } = useParams();
  const [list, setList] = useState([]);
  const [pageNum, setPage] = useState(1);
  const mounted = useRef(false);
  const newsList = useRef([]);
  const pageSize = 10;

  useEffect(()=>{
    if (mounted.current) {
      getNews();
    } else {
      mounted.current = true;
    }
  },[]);

  let getNews = async () => {
console.log('getNews');
    let data = [];
    let c = (category) ? category : 'all';
    if (newsList){
      if (newsList.current.length < 1) {
        const q = (c === 'all') ? '' : `&category=${c}`;
        console.log(`https://newsapi.org/v2/top-headlines?country=kr${q}&apiKey=ffdcfcf39450465ba3d128e45f3ff6aa`);
        const resp = await axios.get(
          `https://newsapi.org/v2/top-headlines?country=kr${q}&apiKey=ffdcfcf39450465ba3d128e45f3ff6aa`
        ).then((response) => response.data);
        newsList.current = resp.articles;
        data = newsList.current;
        // data = newsList.current.slice(0, pageNum * pageSize);
        setList(data);
        // console.log(data);
      }
    }
  };

  let rendering = () => {
    let result = '';
    if (list && list.length > 0) {
      console.log({list});
      result = (<News articles={list} />);
    }
    return result;
  };

  return (
    <div className="">
      { rendering() }
    </div>
  );
}
