import './css/App.css';
import './css/custom.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavMenu from './components/NavMenu';
import NewsList from './components/NewsList';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './pages/error/NotFound';

function App() {

  return (
    <div className="App">
      <NavMenu />
      <div className='content'>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<NewsList />} /> */}
          <Route path='/:category' element={<NewsList />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
